import { Box, Container, Hidden } from '@material-ui/core';
import React from 'react';
import { Layout } from 'src/components';
import UseInstallments from 'src/components/Landing/UseInstallments';
import JulyAppDownload from 'src/components/Merchant/Campaign/2022AAA_202211/JulyAppDownload';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import { SITE_METADATA } from 'src/constants';
import PaidyLogoImg from 'src/images/merchant/campaign/202304_6pay/paidy-logo.png';
import BannerImg from 'src/images/campaign/202504_npaycampaign/banner.png';
import BannerSpImg from 'src/images/campaign/202504_npaycampaign/banner-sp.png';
import SixPayUpsellImg from 'src/images/campaign/202504_npaycampaign/6pay_upsell.png';
import QrAndBadgesSwitch from 'src/components/QrAndBadgesSwitch';
import CartImg from 'src/images/6pay/cart.png';
import PayStep3Img from 'src/images/6pay/pay-step-3.png';
import PayStep4Img from 'src/images/campaign/202504_npaycampaign/6pay/step4.svg';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import styles from 'src/styles/pages/202310_n_pay.module.scss';

const SEOProps = {
  title: SITE_METADATA.campaign_202504_npaycampaign.title,
  description: SITE_METADATA.campaign_202504_npaycampaign.description,
};

const TERMS = [
  {
    title: 'キャンペーン期間',
    description: '2025年4月1日（火）0:00～2025年4月19日（土）23:59',
  },
  {
    title: 'キャンペーン概要・特典',
    description: (
      <>
        キャンペーン期間中に対象ショップの決済画面で「あと払い（ペイディ）」を選択し、6回あと払いを初めて利用して6,000円以上購入すると500円キャッシュバック、12回あと払いを初めて利用して12,000円以上購入すると1,000円をキャッシュバック。6回あと払いと12回あと払いを異なるショップで利用すると、1,500円キャッシュバック。
        <br />
        <br />
        <small>
          ※同一加盟店で6回払いと12回あと払いを利用した場合は、12回あと払いのみがキャンペーン対象となります。
          <br />
          ※キャンペーン終了後、2025年9月30日までに特典分がキャッシュバックされる予定です。
          <br />
          ※キャッシュバックはペイディアプリおよびMyPaidyに反映されます。決済画面には反映されません。
          <br />
          ※一括払いで購入後、ペイディアプリから６回もしくは12回あと払いに変更した場合はキャンペーン対象外となりますのでご注意ください。
        </small>
      </>
    ),
  },
  {
    title: 'キャンペーン対象',
    description:
      '対象ショップの決済画面で「あと払い（ペイディ）」をご利用のお客様',
  },
  {
    title: 'ご注意事項',
    description: (
      <ul>
        <li>
          キャッシュバックは、決済画面でペイディを選択した場合のみ対象です。ペイディカードの利用は対象外となります。
        </li>
        <li>
          キャンペーン終了後2025年9月30日までに特典分がキャッシュバックされる予定です。また予定日より遅れることもありますので、予めご了承ください。
        </li>
        <li>
          キャッシュバック付与のタイミングで、ペイディの利用が無い場合、付与日以降でペイディをご利用いただいた際に、該当キャッシュバックが付与されます。あらかじめご了承ください。
        </li>
        <li>
          株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        </li>
        <li>
          不正と判断された場合やキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        </li>
        <li>
          その他条件はペイディ
          <OutboundLink
            href="https://terms.paidy.com/cashback/"
            target="_blank"
            rel="noopener noreferrer"
          >
            キャッシュバック及びクーポン利用規約
          </OutboundLink>
          に準じます。
        </li>
        <li>
          ペイディに関するお問い合わせは
          <OutboundLink
            href="https://cs.paidy.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            ペイディヘルプページ
          </OutboundLink>
          へご連絡ください。
        </li>
      </ul>
    ),
  },
];

const SIXPAY = [
  {
    ImgComponent: () => <QrAndBadgesSwitch />,
    figCaption: 'ペイディアプリから本人確認する。',
  },
  {
    ImgComponent: () => (
      <img alt="cart" src={CartImg} width={184} height={184} />
    ),
    figCaption: 'ショップで欲しい商品をカートに入れる。',
  },
  {
    ImgComponent: () => (
      <img alt="pay-step-3" src={PayStep3Img} width={184} height={184} />
    ),
    figCaption:
      'お支払い方法で「あと払い（ペイディ）」を選択して、メールアドレスと携帯電話番号を入力する｡',
  },
  {
    ImgComponent: () => (
      <img alt="pay-step-4" src={PayStep4Img} width={184} height={184} />
    ),
    figCaption: 'ご希望のお支払い回数を選んで完了。',
  },
];

export default function Campaign202310NPay() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <Box className={styles.page}>
        <header className={styles.sectionHeader}>
          <img alt="paidy-logo" width={114} height={45} src={PaidyLogoImg} />
        </header>

        <section className={styles.sectionBanner}>
          <Hidden xsDown>
            <img alt="banner" height={522} width={1440} src={BannerImg} />
          </Hidden>
          <Hidden smUp>
            <img alt="banner-sp" width="100%" src={BannerSpImg} />
          </Hidden>
        </section>

        <section className={styles.sectionTerms}>
          <Container className={styles.container}>
            {TERMS.map((term) => (
              <Box key={term.title}>
                <p className={styles.title}>{term.title}</p>
                <p className={styles.description}>{term.description}</p>
                {term.notes && <p className={styles.notes}>{term.notes}</p>}
              </Box>
            ))}
          </Container>
        </section>

        <section className={styles.section6Pay}>
          <Box className={styles.top}>
            <>
              <Hidden xsDown>
                <img
                  alt="6pay"
                  width={367}
                  height={400}
                  src={SixPayUpsellImg}
                />
              </Hidden>
              <Hidden smUp>
                <img
                  alt="6pay"
                  width={247}
                  height={268}
                  src={SixPayUpsellImg}
                />
              </Hidden>
            </>
            <Box className={styles.texts}>
              <p className={styles.title}>
                高額商品も、分割手数料無料*の
                <br />
                6・12回あと払いでかしこく購入。
                <br />
                キャッシュバックでお得に 始める <br />
                チャンス！
              </p>
              <p className={styles.text}>
                ペイディなら、6・12回あと払いが分割手数料無料*。お支払い総額はそのまま、月々のお支払い金額を調整できるので、高額商品もかしこく購入できます。
                このチャンスに、6・12回あと払いを利用してお得にお買い物を楽しみませんか？
              </p>
              <p className={styles.notes}>
                *口座振替・銀行振込のみ無料
                <br />
                ※6回あと払いは1回のお買い物につき6,000円以上、12回あと払いは1回のお買い物につき12,000円以上のお支払いで利用可能です
                <br />
                ※選択できる支払い回数は加盟店により異なります。
              </p>
            </Box>
          </Box>
          <UseInstallments
            title="6・12回あと払いのご利用方法"
            data={SIXPAY}
            noArrows
          />
        </section>
        <JulyAppDownload />
        <SectionCampaignFooter isThemeBlack />
      </Box>
    </Layout>
  );
}
